import React, { Component } from 'react';
import {
  Category, 
  PodcastBubble,
  PodcastDetails 
} from './components';

import './App.css';
import backend from './helpers/backend';
import AdComponent from './AdComponent';

const CONSTANTS = {
  hideClass: 'Hide',
  showClass: 'Show',
  fade: 'Fade',
  spaceDelimiter: ' ',
  categories: 'Categories'
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      podcastSelected: false,
      podcastSelectedData: {},
      podcastHoveredData: {},
      podcasts: [],
      filteredPodcasts: [],
      categories: [
        "Beauty",
        "Tech",
        "News",
        "Health",
        "Culture",
        "Sports",
        "Music",
        "Television",
        "Relationships",
        "Money",
        "Politics"
      ]
    }
    
    this.addOpacityToCategories = this.addOpacityToCategories.bind(this);
    this.removeOpacityClassFromCategories = this.removeOpacityClassFromCategories.bind(this);
    this.fadeUnHoveredPodcastBubbles = this.fadeUnHoveredPodcastBubbles.bind(this);
    this.removeFadeEffect = this.removeFadeEffect.bind(this);
    this.onPodcastSelected = this.onPodcastSelected.bind(this);
    this.onPodcastExitClicked = this.onPodcastExitClicked.bind(this);
    this.filterPodcasts = this.filterPodcasts.bind(this);

    this.categoriesRef = React.createRef();
    this.headerRef = React.createRef();
    this.podcastHoverRef = React.createRef();
    this.podcastContainerRef = React.createRef();
  }

  async componentDidMount() {
    const podcastDataIsNotLoaded = !this.state.podcasts.length > 0;
    if (podcastDataIsNotLoaded) {
      const data = await this.getPodcastData();
      this.setState({ 
        podcasts: data,
        filteredPodcasts: data
      });
    }
  }

  async getPodcastData() {
    let data = await backend.fetchPodcastData();
    return data;
  }

  onPodcastSelected(event) {
    const data = event.target.dataset;
    this.setState({
      podcastSelected: true,
      podcastSelectedData: data
    });
  }

  onPodcastExitClicked() {
    this.setState({
      podcastSelected: false,
      podcastSelectedData: {}
    });
  }

  addOpacityToCategories(event) {
    this.fadeUnHoveredPodcastBubbles();
    const data = event.target.dataset;
    this.setState({ podcastHoveredData: data });
    this.podcastHoverRef.current.classList.add(CONSTANTS.showClass);
    this.headerRef.current.classList.add(CONSTANTS.hideClass);
    this.categoriesRef.current.classList.add(CONSTANTS.hideClass);
  }

  removeOpacityClassFromCategories() {
    this.removeFadeEffect();
    this.podcastHoverRef.current.classList.remove(CONSTANTS.showClass);
    this.headerRef.current.classList.remove(CONSTANTS.hideClass);
    this.categoriesRef.current.classList.remove(CONSTANTS.hideClass);
  }

  fadeUnHoveredPodcastBubbles() {
    const childNodes = this.podcastContainerRef.current.childNodes;
    if (childNodes) {
      childNodes.forEach((node) => {
        node.className = node.className += CONSTANTS.spaceDelimiter + CONSTANTS.fade;
      });
    }
  }

  removeFadeEffect() {
    const childNodes = this.podcastContainerRef.current.childNodes;
    if (childNodes) {
      childNodes.forEach((node) => {
        node.className = node.className.split(CONSTANTS.spaceDelimiter)[0];
      });
    }
  }

  renderCategories() {
    return (
      <div className="Categories" ref={this.categoriesRef}>
        {this.state.categories.map(category => {
          return (
            <Category
              onClick={this.filterPodcasts}
              key={category}
              title={category}
            />
          );
        })}
      </div>
    );
  }

  filterPodcasts(event) {
    this.onPodcastExitClicked();
    const { podcasts } = this.state;

    const title = event.target.title;
    const categoriesTitleClicked = title === CONSTANTS.categories;

    if (categoriesTitleClicked) {
      this.setState({ filteredPodcasts: podcasts });
    } else {
      const results = podcasts.filter(podcast => {
        return podcast.categories.includes(title);
      });
      this.setState({ filteredPodcasts: results });
    }
  }

  renderHoverText() {
    const { podcastHoveredData } = this.state;
    return (
      <div className="PodcastHoverDescription" ref={this.podcastHoverRef}>
        <div className="PodcastHoverTitle">{podcastHoveredData.podcastTitle}</div>
        <div className="PodcastHoverHost">{podcastHoveredData.podcastHostname}</div>
      </div>
    );
  }

  renderPodcastSection() {
    if (this.state.podcastSelected) {
      this.removeOpacityClassFromCategories();
      return this.renderPodcastDetails();
    }
    return this.renderPodcastBubbles();
  }

  renderPodcastDetails() {
    return (
      <PodcastDetails 
        data={this.state.podcastSelectedData} 
        onExitClicked={this.onPodcastExitClicked} 
      />
    );
  }

  renderPodcastBubbles() {
    const { filteredPodcasts } = this.state;
    const podcastDataIsPopulated = filteredPodcasts.length > 0;
    const ctrl = this;

    if (podcastDataIsPopulated) {
      return (
        <div className="PodcastBubbles" ref={ctrl.podcastContainerRef}>
          {filteredPodcasts.map((user) => {
            const key = `${user.podcastTitle}${user.hostName}`;
            const alt = `${user.imageLink300} By ${user.hostName}`;

            return <PodcastBubble
              key={key}
              onClick={ctrl.onPodcastSelected}
              onMouseEnter={ctrl.addOpacityToCategories}
              onMouseLeave={ctrl.removeOpacityClassFromCategories}
              image={user.imageLink300}
              userData={user}
              alt={alt}
            />
          })}
        </div>
      );
    } else {
      return (
        <div className="NotFound"></div>
      );
    }
  }

  render() {
    return (
      <div className="App">
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <div
          title={CONSTANTS.categories}
          className="Header"
          ref={this.headerRef}
          onClick={this.filterPodcasts}
        >
          Categories
        </div>
        {this.renderCategories()}
        {this.renderHoverText()}
        {this.renderPodcastSection()}
        <div className="Footer">
          Black Podcasts
        </div>
        <AdComponent />
      </div>
    );
  }
}

export default App;
