import React from 'react';
import './Category.css';

const Category = (props) => {
  return(
    <div 
      onClick={props.onClick} 
      className="Category"
      title={props.title}
    >
      {props.title}
    </div>
  );
}

export default Category;