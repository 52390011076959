import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyC7OEmIJOeIX1tzbKDurt5lP6zmtsJzHno",
  authDomain: "black-podcasts.firebaseapp.com",
  databaseURL: "https://black-podcasts.firebaseio.com",
  projectId: "black-podcasts",
  storageBucket: "gs://black-podcasts.appspot.com",
  messagingSenderId: "790274844007",
  appId: "1:790274844007:web:65e06fb0f23962e9"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
