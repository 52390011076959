import firebase from 'firebase';

const backend = {};

backend.fetchPodcastData = async () => {
  let db = firebase.firestore();
  let usersRef = db.collection("users");
  
  return usersRef.get().then(function(querySnapshot) {
    const data = [];
    querySnapshot.forEach(doc => {
      data.push(doc.data());
    });

    return data;
  })
  .catch(err => {
    console.log("Error getting documents:", err);
    return [];
  });
}

export default backend;


