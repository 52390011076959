import React, { Component } from 'react';
import "./PodcastBubble.css";

class PodcastBubble extends Component {

  renderPodcastLabel() {
    const { userData } = this.props;
    // If user's device is mobile
    if (/Mobi|Android/.test(navigator.userAgent)) {
      return (
        <div className="PodcastLabels">
          <div className="PodcastTitleLabel">{userData.podcastTitle}</div>
          <div className="PodcastHostNameLabel">{userData.hostName}</div>
        </div>
      );
    }
    return;
  }

  render() {
    const {
      onMouseEnter,
      onMouseLeave,
      onClick,
      userData,
      image,
      alt
    } = this.props;

    return(
      <div className="PodcastContainer">
        <div 
          className="PodcastBubble"
          onClick={onClick}
        >
          <img
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-podcast-title={userData.podcastTitle}
            data-podcast-hostname={userData.hostName}
            data-podcast-description={userData.description}
            data-podcast-link={userData.podcastLink}
            data-podcast-image300={userData.imageLink300}
            data-podcast-image512={userData.imageLink512}
            src={image} 
            alt={alt}
          />
        </div>
        {this.renderPodcastLabel()}
      </div>
    );
  }
}
    

export default PodcastBubble;