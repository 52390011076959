import React from 'react';
import './PodcastDetails.css';

const PodcastDetails = (props) => {
  const { data, onExitClicked } = props;
  return (
    <div className="Container">
      <img className="PodcastImage" src={data.podcastImage512} alt={data.alt} />
      <div className="TextContainer">
        <div className="TopBar">
          <div className="PodcastTitle">{data.podcastTitle}</div>
          <div className="ExitButton" onClick={onExitClicked}>X</div>
        </div>
        
        <div className="PodcastHost">{data.podcastHostname}</div>
        <div className="Description">
          <span>{data.podcastDescription}</span>
        </div>
        <a rel="noopener noreferrer" target="_blank"  href={data.podcastLink} className="TapButtonContainer">
          <div className="TapButton">
            Listen
          </div>
        </a>
      </div>
      
    </div>
  )
}

export default PodcastDetails;